import request from '@/utils/request'

//游戏
export function login(data){
    return request({
        url:"/admin/passport/index",
        method:"post",
        data
    })
}
//获取路由
export function initIndex(){
    return request({
        url:"/admin/ajax/initIndex",
        method:"post"
    })
}
export function editinfo(data){
    return request({
        url:"/admin/provider/editinfo",
        method:"post",
        data
    })
}
//账号信息
export function getUserList(){
    return request({
        url:"/admin/game.provider/info",
        method:"get"
    })
}
//按钮权限表
export function btninfo(){
    return request({
        url:"/admin/Passport/userinfo",
        method:"get"
    })
}
//支付服务商列表
export function payerList(){
    return request({
        url:"/admin/ajax/payerList",
        method:"get"
    })
}
//结算中心
export function getSettleMent(params){
    return request({
        url: "/admin/channel.settlement/earnings",
        method: "get",
        params
    })
}
//获取可提现金额
export function allowMoney(params){
    return request({
        url: "/admin/channel.settlement/allowMoney",
        method: "get",
        params
    })
}
//获取每日收益
export function dayData(params){
    return request({
        url: "/admin/channel.settlement/dayData",
        method: "get",
        params
    })
}
//任务配置
export function config(params){
    return request({
        url: "/api/task/config",
        method: "get",
        params
    })
}
//米粒选择游戏
export function selectGame(params){
    return request({
        url: "admin/welfare.rice/selectGame",
        method: "get",
        params
    })
}
//GM自定义任务
export function customStep(params){
    return request({
        url: "admin/welfare.rice/customStep",
        method: "get",
        params
    })
}
//首页统计数据
export function platformstatistics(params){
    return request({
        url:"/admin/game.home/platformstatistics",
        method: "get",
        params
    })
}
//首页应用列表之盒子列表
export function applicationlist(params){
    return request({
        url:"/admin/game.home/applicationlist",
        method: "get",
        params
    })
}
//首页应用列表之游戏列表
export function gameapplicationlis(params){
    return request({
        url:"/admin/game.home/gameApplicationlist",
        method: "get",
        params
    })
}
//任务投放编辑
export function editStep(params){
    return request({
        url:"admin/welfare.rice/editStep",
        method: "get",
        params
    })
}
//任务初始化数据
export function initConfig(params){
    return request({
        url:"admin/welfare.TaskConfig/initConfig",
        method: "get",
        params
    })
}
//易卡账户解绑
export function secureBind(params){
    return request({
        url:"admin/welfare.RiceBalance/secureBind",
        method: "get",
        params
    })
}
//获取官方任务是否开启的状态
export function recordStatus(params){
    return request({
        url:"/admin/welfare.RiceBalance/riceRecord",
        method: "get",
        params
    })
}
//投放列表
export function release(params){
    return request({
        url:"admin/welfare.rice/index",
        method: "get",
        params
    })
}
//是否绑定易卡商城
export function isBind(params){
    return request({
        url:"admin/welfare.rice/isBind",
        method: "get",
        params
    })
}
//自定义累计充值
export function addPay(params){
    return request({
        url:"admin/welfare.rice/addPay",
        method: "get",
        params
    })
}

//账号信息编辑
export function editUserList(data) {
    return request({
        url: "/admin/game.provider/editinfo",
        method: "post",
        data,
        headers: {'Content-Type': 'multipart/form-data '}
    })
}

//修改测试账号
export function changeTestAccount(data) {
    return request({
        url: "/admin/game.provider/change",
        method: "post",
        data: {is_test: data}
    });
}

//官方任务是否开启
export function officialStatus(data) {
    return request({
        url: "admin/welfare.rice/officialStatus",
        method: "post",
        data,
        headers: {'Content-Type': 'multipart/form-data '}
    })
}

//积分任务动态显示
export function isShowJiFen(data){
    return request({
        url:"admin/welfare.rice/isShowJiFen",
        method:"post",
        data,
        headers:{'Content-Type':'multipart/form-data '}
    })
}
//获取米粒记录表格数据
export function riceRecord(data){
    return request({
        url:"/admin/welfare.RiceBalance/riceRecord",
        method:"post",
        data,
        headers:{'Content-Type':'multipart/form-data '}
    })
}
//所需米粒
export function estimateRice(data){
    return request({
        url:"/admin/welfare.rice/estimateRice",
        method:"post",
        data,
        headers:{'Content-Type':'multipart/form-data '}
    })
}
//新米粒配置添加
export function addConfig(data){
    return request({
        url:"admin/welfare.TaskConfig/addConfig",
        method:"post",
        data,
        headers:{'Content-Type':'multipart/form-data '}
    })
}
//新米粒配置修改
export function editConfig(data){
    return request({
        url:"admin/welfare.TaskConfig/editConfig",
        method:"post",
        data,
        headers:{'Content-Type':'multipart/form-data '}
    })
}
//米粒投放配置添加
export function addStep(data){
    return request({
        url:"/admin/welfare.rice/addStep",
        method:"post",
        data,
        headers:{'Content-Type':'multipart/form-data '}
    })
}
//米粒投放配置编辑
export function taskEditControl(data){
    return request({
        url:"admin/welfare.rice/editStep",
        method:"post",
        data,
        headers:{'Content-Type':'multipart/form-data '}
    })
}
//米粒任务配置开启任务按钮
export function openSuccess(params){
    return request({
        url:"admin/welfare.rice/openSuccess",
        method: "get",
        params
    })
}
//米粒支付
export function ricePay(data){
    return request({
        url:"/admin/welfare.rice/ricePay",
        method:"post",
        data,
        headers:{'Content-Type':'multipart/form-data '}
    })
}
//获取验证码
export function getCode(data){
    return request({
        url:"/admin/game.provider/sms",
        method:"post",
        data,
        headers:{'Content-Type':'multipart/form-data '}
    })
}
//绑定易卡账户时发送验证码
export function getYkCode(data){
    return request({
        url:"/admin/welfare.rice/getYkCode",
        method:"post",
        data,
        headers:{'Content-Type':'multipart/form-data '}
    })
}
//绑定易卡账户
export function bindYk(data){
    return request({
        url:"/admin/welfare.rice/bindYk",
        method:"post",
        data,
        headers:{'Content-Type':'multipart/form-data '}
    })
}
//获取协议
export function getAgreement(data){
    return request({
        url:"/admin/game.home/getAgreement",
        method:"post",
        data,
        headers:{'Content-Type':'multipart/form-data '}
    })
}
//注册
export function register(data){
    return request({
        url:"/admin/passport/register",
        method:"post",
        data,
        headers:{'Content-Type':'multipart/form-data '}
    })
}
//同意协议
export function addAgreement(data){
    return request({
        url:"/admin/game.home/addAgreement",
        method:"post",
        data,
        headers:{'Content-Type':'multipart/form-data '}
    })
}
//修改支付密码
export function changePassword(data){
    return request({
        url:"/admin/game.provider/password",
        method:"post",
        data,
        headers:{'Content-Type':'multipart/form-data '}
    })
}
//获取当前研发商状态
export function getStatus(params){
    return request({
        url:"/admin/game.provider/getStatus",
        method:"get",
        params,
    })
}
//获取账户米粒余额
export function queryRice(params){
    return request({
        url:"/admin/welfare.RiceBalance/queryRice",
        method:"get",
        params,
    })
}
//获取银行卡信息
export function bankInfo(params){
    return request({
        url:"/admin/ajax/bankInfo",
        method:"get",
        params,
    })
}

//开通微信支付
export function uploadAccount(){
    return request({
        url:"/admin/game.provider/uploadAccount",
        method:"get"
    })
}
//GM提现
export function withdrawals(data){
    return request({
        url: "/admin/channel.settlement/withdrawals",
        method: "post",
        data,
        headers: {'Content-Type': 'multipart/form-data '}
    })
}
//沙盒后台修改密码
export function forgetpassword(data){
    return request({
        url:"/admin/passport/forgetpassword",
        method:"post",
        data,
        headers:{'Content-Type':'multipart/form-data '}
    })
}
//修改银行卡号
export function editBankInfo(data){
    return request({
        url:"/admin/game.provider/bankInfo",
        method:"post",
        data,
        headers:{'Content-Type':'multipart/form-data '}
    })
}